/**
 * This is the index file for the page where users can request the data that has been collected in the course of our business practices
 * business.com/datarequest
 */
import React from 'react'
import styled from '@emotion/styled'

// Import Components
import CcpaForm from 'components/CcpaForm'
import { Layout, SimpleHeader } from '@jelly/components'

const META = {
	canonical: `${process.env.GATSBY_SITE_HOST}/datarequest/`,
	description:
		'You have the right to opt out of the sale of your personal information by business.com, buyerzone.com and businessnewsdaily.com.',
	robots: 'noindex, nofollow',
	title: 'Request for Data - California Residents - business.com',
}

const HEADER_PROPS = {
	breadcrumbs: { primary: { name: 'Data Request', slug: 'datarequest' } },
	subTitle: 'Last Updated: January 1, 2020',
	title: 'Request for Data - California Residents',
}

const DATA_REQUEST_INTRO_TEXT = `As a resident of the state of California, you have the right to request from
business.com your personal data as collected in the course of our business
practices. Upon submission of the below form - or by calling us at 888-393-5000 - we
will disclose to you: (1) the categories of personal information we collected about
you; (2) the categories of information we sold about you and the categories of third
parties to whom the information was sold, by category or categories of information
for each third party to whom the information was sold; and (3) the categories of
information we disclosed about you to fulfill our business purpose. You will receive
a receipt of your submission within 10 days via email, and you will receive a
response to your request with the provided data within 45 days via email. Please be
advised that we may contact you to verify your identify and to confirm you are a
California taxpayer as defined by law.`

const ConnectWrapper = styled.div`
	height: 100%;
	width: 100%;
`
const ContentWrapper = styled.div`
	${({ theme }) => theme.maxWidth.L};
	margin: 0 auto;
	padding: 1rem 0 3rem;
`

function DataRequestPage() {
	return (
		<ConnectWrapper>
			<Layout inverseLinkColors={true} meta={META}>
				<SimpleHeader canonical={META.canonical} {...HEADER_PROPS} />
				<ContentWrapper>
					<CcpaForm isDataRequestForm={true} formIntroText={DATA_REQUEST_INTRO_TEXT} />
				</ContentWrapper>
			</Layout>
		</ConnectWrapper>
	)
}

export default DataRequestPage
